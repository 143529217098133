<template>
  <div class="custom-tab-component" :style="{ width: width }">
    <div
      v-for="(tab, index) in items"
      :key="index"
      @click="selectTab(index)"
      class="custom-tab"
      :class="{
        'active-tab': activeTab === index,
      }"
    >
      <h6>{{ tab.title }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Number,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: "50%",
    },
    tabInnerWidth: {
      type: String,
      required: false,
      default: "60px",
    }
  },
  data() {
    return {};
  },
  methods: {
    async selectTab(index) {
      this.$emit("changeTab", index);
    },
  },
};
</script>

<style scoped>
.custom-tab-component {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  min-height: 35px;
  border: 1px #934be6 solid;
  display: flex;
  justify-content: space-between;
  gap: 0.1rem;
  border-radius: 2px;
  padding: 0.1rem;
}

.custom-tab {
  border: none;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  height: 100%;
  border-radius: 2px;
}

.custom-tab:hover {
  background-color: #934be6;
  transition: 0.3s;
}

.custom-tab.active-tab {
  background-color: #934be6;
}

.custom-tab-component h6 {
  font-size: 13px;
}
</style>
