<template>
  <div class="f align-center sb item_row">
    <div class="checkbox-item">
      <CheckBoxButton v-model="isChecked" />
    </div>
    <div class="f skin">
      <div class="f g20" @click="selectItem">
        <a style="cursor: pointer" class="thumb null">
          <div class="thumb_shadow" style="color: rgb(235, 75, 75)"></div>
          <img
            :src="item.image"
            class="img-item-mid item-bg"
            :alt="item.name"
            loading="lazy"
          />
        </a>
        <div class="name ovh">
          <div class="f mb-2">
            <div class="f flex-column">
              <span class="mid-span bold" :style="{ color: typeColor }">{{
                getType()
              }}</span>
              <span class="name-span" :style="{ color: item.rarity_color }">{{
                item.short_name
              }}</span>
              <span class="mid-span" :style="{ color: 'white' }">{{
                item.exterior
              }}</span>
            </div>
            <button
              @click="$emit('copyText', item.name)"
              class="btn_copyname mx-2"
            >
              <VueIcon height="18px" color="white" icon="bx:copy" />
            </button>
          </div>
          <div class="f align-bottom">
            <VueIcon height="18px" color="white" icon="mdi:steam" />
            <span
              :style="{ color: '#' + item.rarity_color }"
              class="text-white mx-2"
            >
              $ {{ item.referancePrice }}
            </span>
          </div>
        </div>
      </div>
      <div class="f align-center">
        <div>
          <template v-if="item.buyEditingPrice">
            <div class="f">
              <div class="inner-input-div">
                <input
                  v-model="buyPrice"
                  type="number"
                  step="0.01"
                  min="0"
                  max="1000"
                  class="input-area-dark"
                  @keyup.enter="updatePrice('steam')"
                />
              </div>
              <button
                class="edit-btn f align-center"
                @click="updatePrice('steam')"
              >
                ✔️
              </button>
            </div>
            <div>
              <span class="text-white mid-font"
                >Listing Date:
                {{ DateUtils.getOnlyDate(item.created_at) }}</span
              >
            </div>
            <div>
              <span class="mid-font" :style="{ color: color }"
                >Since: {{ diff }} days</span
              >
            </div>
          </template>
          <template v-else>
            <div class="f sb">
              <div>
                <span class="mid-font gray bold">Buy Price:</span>
                <span
                  class="m-r-s text-white"
                  @click="startEditingPrice(item, 'steam', null)"
                >
                  $ {{ item.buy_price / 1000 || 0 }}
                </span>
              </div>
              <button
                class="edit-btn f align-center"
                @click="startEditingPrice(item, 'steam', null)"
              >
                ✏️
              </button>
            </div>
            <div>
              <span class="mid-font gray bold"
                >Listing Date:
                {{ DateUtils.getOnlyDate(item.created_at) }}</span
              >
            </div>
            <div>
              <span class="mid-font" :style="{ color: color }"
                >Since: {{ diff }} days</span
              >
            </div>
          </template>
        </div>
      </div>
    </div>

    <div v-if="item.markets && item.markets.length > 0" class="item-right">
      <div
        v-for="(market, index) in item.markets"
        :key="index"
        class="f g10 align-center"
      >
        <div v-if="market.is_listed" class="">
          <img class="img-small" :src="getMarketIcon(market.market_name)" />
          <div>
            <template v-if="market.editing_price">
              <div class="f">
                <div class="inner-input-div">
                  <input
                    v-model="market.newPrice"
                    type="number"
                    step="0.01"
                    min="0"
                    max="1000"
                    class="input-area-dark"
                    @keyup.enter="updatePrice(market)"
                  />
                </div>
                <button
                  class="edit-btn f align-center"
                  @click="updatePrice(market)"
                >
                  ✔️
                </button>
              </div>
              <div>
                <span class="text-white mid-font"
                  >Min: {{ (market.market_price / 1000).toFixed(2) }}</span
                >
              </div>
              <div>
                <span class="mid-font gray bold"
                  >Profit:
                  <span
                    :style="{
                      color: market.profit > 0 ? '#7CFC00' : 'red',
                    }"
                  >
                    ${{ market.profit.toFixed(2) }} (%{{
                      market.profitRatio
                    }})</span
                  >
                </span>
              </div>
            </template>
            <template v-else>
              <div class="f sb align-center g5">
                <span class="text-white mid-font">
                  Price: $
                  {{ market.sale_price / 1000 }}
                </span>
                <button
                  class="edit-btn f align-center"
                  @click="startEditingPrice(item, market.slug, market)"
                >
                  ✏️
                </button>
              </div>
              <div>
                <span class="mid-font gray bold"
                  >Min: ${{
                    market.market_price
                      ? (market.market_price / 1000).toFixed(2)
                      : "0"
                  }}</span
                >
              </div>
              <div class="profit-div"
              :style="{ background: market?.profit  > 0 ? '#31b76833' : '#ff38380d' }">
                <span class="mid-font gray bold">
                  <span
                    :style="{
                      color: (market?.profit ?? 0) > 0 ? '#7CFC00' : 'red',
                    }"
                  >
                  {{ market.profitRatio >= 0 ? "+" : "" }}
                    ${{ (market?.profit ?? 0).toFixed(1) }} (%{{
                      market.profitRatio ? market.profitRatio.toFixed(1) : "0"
                    }})
                  </span>
                </span>
              </div>
            </template>
          </div>
        </div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import steamIcon from "../../assets/images/steam-white.png";
import MarketOptions from "../../core/constants.js";
import CheckBoxButton from "../checkbox/CheckboxButton.vue";
import DateUtils from "@/utils/DateUtils";
import moment from "moment";

export default {
  data() {
    return {
      isChecked: false,
      MarketOptions,
      steamIcon: steamIcon,
      newPrice: 0,
      buyPrice: this.item?.buy_price / 1000,
      currentTime: moment(),
      color: "white",
      typeColor: "white",
      diff: 0,
      markets: ["waxpeer", "shadowpay", "csgotm", "haloskins"],
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    buy: {
      type: String,
      default: "",
    },
    selectedMarket: {
      type: String,
      default: "",
    },
  },
  methods: {
    getMarketIcon(name) {
      const res = this.MarketOptions.find((e) => e.name == name);
      if (!res) return null;
      return res.icon;
    },
    updatePrice(market) {
      if (market === "steam") {
        const market = { market_id: 1, slug: "steam" };
        return this.$emit("updatePrice", this.item, this.buyPrice, market);
      }

      return this.$emit("updatePrice", this.item, market.newPrice, market);
    },
    startEditingPrice(item, marketName, market) {
      if (marketName === "steam") {
        const market = { market_id: 1, slug: "steam" };
        return this.$emit("startEditingPrice", item, marketName, market);
      }
      return this.$emit("startEditingPrice", item, marketName, market);
    },
    dayDiffColor() {
      this.diff = DateUtils.getDateDiff(
        this.item?.created_at || new Date(),
        this.currentTimecurrentTime
      );
      if (this.diff < 8) {
        return (this.color = "#31b768");
      }
      if (this.diff > 8 && this.diff < 15) {
        return (this.color = "#cf9948");
      }
      return (this.color = "#ff3838");
    },
    getType() {
      if (this.item.is_stattrak) {
        this.typeColor = "#CF6830";
        return "StatTrak™";
      }
      if (this.item.is_souvenir) {
        this.typeColor = "#FFD601";
        return "Souvenir";
      }

      this.typeColor = "white";
      return this.item.type;
    },
    selectItem() {
      this.$emit("select-item", this.item);
      this.isChecked = this.item.isChecked;
    },
    checkActive() {
      this.isChecked = this.item.isChecked;
    },
  },
  created() {
    this.dayDiffColor();
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
  components: {
    CheckBoxButton,
  },
  watch: {
    "item.isChecked"() {
      this.checkActive();
    },
  },
};
</script>

<style scoped>
.profit-div {
  padding: 0.2rem 0.2rem;
  border-radius: 4px;
  text-align: center;
}

.item_row:hover .img-item-mid {
  transition: 0.2s;
  transform: scale(1.1);
  filter: drop-shadow(0 0 11px #757373);
}

.checkbox-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 27px;
  height: 26px;
}

.skin {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.mid-span {
  padding: 0;
}

.name-span {
  font-size: 14.5px;
}

.mid-font span {
  font-size: 13px;
}

.item_row:not(hover) .img-item-mid {
  transition: 0.2s;
  transform: scale(1);
}

.item-right {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.img-item-mid {
  height: 70px;
  width: 90px;
}

.img-small {
  width: 22px;
  height: 22px;
}

.btn_copyname {
  border: 0;
  padding: 0;
  border-radius: 0.4rem;
  outline: none;
  background: rgba(0, 0, 0, 0);
  font-size: inherit;
  line-height: 1.2;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;
}

.date-container {
  display: flex;
  align-items: center;
}

.custom-drop-alt {
  background-color: #4d555d;
  font-size: 15px;
  font-weight: 500;
  /* height: 50px; */
  width: 200px;
  color: white;
  padding: 4px;
  font-size: 16px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.edit-btn {
  background-color: #212121;
  border: 1px solid rgb(100, 99, 99);
  height: 28px;
  width: 30px;
  font-size: 14px;
  color: white;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-btn:hover {
  background-color: #626060;
  color: white;
}

.small-img-button {
  height: 15px;
  width: 15px;
}

.mid-font {
  font-size: 12px;
  font-weight: 600;
}

.inner-input-div {
  height: 2rem;
  width: 90px;
}

.input-area-dark {
  background-color: black;
  color: white;
  font-weight: 600;
  width: 90%;
  min-width: 60px;
}

.img-item {
  height: 70px;
  width: 80px;
}

.line {
  height: 80px;
  width: 2px;
  background-color: #313156
}

.item_row {
  background: #21242c;
  border-radius: 0.4rem;
  margin-block: 0.4rem;
  padding: 0.5rem 1.4rem;
  position: relative;
  transition: 0.3s;
  max-height: 12rem;
  min-height: 6rem;
  display: grid;
  grid-template-columns: 1fr 1.25fr;
}

.item_row:hover {
  background-color: #303542;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}

.img-xsmall {
  height: 25px;
  width: 25px;
}
</style>
