<template>
  <div class="item_row">
    <div v-if="item?.global_rank == 1" class="crown">
      <VueIcon height="20px" color="#FCEA2A" icon="mdi:crown" />
    </div>
    <div class="inner_column">
      <div class="f sb top_section">
        <div class="f g20">
          <div class="item-bg">
            <img
              style="cursor: pointer"
              @click="goRoute(item)"
              @click.middle.exact="goRoute(item)"
              :src="item.image"
              class="currency-icon"
              :alt="item.name"
              loading="lazy"
            />
          </div>

          <div class="sb f g20 align-center">
            <div class="f flex-column flex-start">
              <span
                class="mid_span"
                :style="{ color: item.color || 'white' }"
                >{{ getType() }}</span
              >
              <span
                class="mid_span"
                :style="{ color: item.color || 'white' }"
                >{{ item.short_name }}</span
              >
              <span class="mid_span">{{ item.exterior }}</span>
              <div
                style="cursor: pointer"
                @click="
                  goExternalLink(
                    `https://steamcommunity.com/market/listings/${item.app_id}/${item.name}`
                  )
                "
                class="steam_wrap f g5"
              >
                <VueIcon height="18px" color="white" icon="mdi:steam" />
                <span class="type_span">
                  {{
                    (item?.referance_price / 1000).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="f flex-column g10 fls">
          <button
            @click="copyText(item.name)"
            aria-label="Copy"
            class="btn_copyname"
          >
            <VueIcon height="22px" color="white" icon="bx:copy" />
          </button>
          <button
            @click="copyText(item.name)"
            aria-label="Copy"
            class="btn_copyname"
          >
            <VueIcon height="22px" color="white" icon="lucide:bookmark" />
          </button>
        </div>
      </div>
      <div class="f sb align-center g8 w100">
        <div class="price_div f flex-column g5">
          <div
            @click="goExternalLink(item.source_url)"
            class="f g5 align-center"
          >
            <img class="img-xsmall" :src="getMarketIcon(item.source_name)" />
            <span class="bold_body text-white">
              {{
                (item?.source_price / 1000).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              }}</span
            >
          </div>
          <div class="f g5 align-center">
            <VueIcon height="22px" color="white" icon="radix-icons:stack" />
            <span class="bold_body text-white">
              {{ item?.source_qty || "1+" }}
            </span>
          </div>
        </div>
        <div class="f flex-column align-center" style="width: 20%">
          <div class="f align-center justify-center">
            <VueIcon
              height="28px"
              color="#3fd986"
              icon="mingcute:arrows-right-line"
            />
          </div>
          <div>
            <div class="f align-center">
              <span
                class="bold_body"
                :style="{ color: item.profit >= 0 ? '#3fd986' : 'red' }"
              >
                {{
                  (item?.profit / 1000).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }}
                {{ item.profitRatio > 0 ? "+" : "" }}
              </span>
            </div>
            <div class="f align-center">
              <span
                class="bold_body"
                :style="{ color: item.profitRatio >= 0 ? '#3fd986' : 'red' }"
              >
                {{
                  (parseFloat(item?.profitRatio) / 100).toLocaleString(
                    "en-US",
                    {
                      style: "percent",
                      maximumFractionDigits: 2,
                    }
                  )
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="f flex-end fle price_div_target">
          <div
            @click="goExternalLink(item.target_url)"
            class="f flex-column g5"
          >
            <div class="f g5 align-center">
              <img class="img-xsmall" :src="getMarketIcon(item.target_name)" />
              <span class="text-white bold_body">
                {{
                  (item.target_price / 1000).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }}
              </span>
            </div>

            <div class="f g5 align-center">
              <VueIcon height="22px" color="white" icon="radix-icons:stack" />
              <span class="text-white bold_body">
                {{ item?.target_qty || "1+" }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarketOptions from "../../core/constants.js";

export default {
  data() {
    return {
      MarketOptions,
      typeColor: "white",
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goRoute(item) {
      this.$emit("goItem", item);
    },
    copyText(item) {
      this.$emit("copyText", item);
    },
    goExternalLink(link) {
      this.$emit("externalLink", link);
    },
    getMarketIcon(name) {
      const found = this.MarketOptions.find(
        (e) => e.name.toLocaleLowerCase() === name.toLocaleLowerCase()
      );
      if (found) return found.icon;
    },
    getType() {
      if (this.item.is_stattrak) {
        this.typeColor = "#CF6830";
        return "StatTrak™";
      }
      if (this.item.is_souvenir) {
        this.typeColor = "#FFD601";
        return "Souvenir";
      }

      this.typeColor = "white";
      return this.item.type;
    },
  },
  computed: {},
};
</script>

<style scoped>
.crown {
  position: absolute;
  top: -10px;
  left: 0px;
}

.grid_section {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  background: #21242c;
  border-radius: 0.2rem;
  padding: 0.6rem 0.8rem;
  transition: 0.2s;
  max-height: 9rem;
  min-height: 6rem;
  border: 1px transparent solid;
}

.top_section {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.1rem 0.2rem;
}

.type_span {
  font-size: 13px;
  font-weight: bold;
}

span {
  font-size: 1.7vh;
  color: white;
}

.item_row {
  background: #21242c;
  border-radius: 0.2rem;
  margin-block: 0.1rem;
  padding: 0.8rem 0.5rem;
  transition: 0.3s;
  max-height: 15rem;
  position: relative;
}

.item_row:hover {
  background-color: #2b2c30;
}

.inner_column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  gap: 10px;
}

.currency-icon {
  height: 80px;
  width: 90px;
}

.price_div {
  border-radius: 3px;
  border: 1px transparent solid;
  padding: 0.3rem 0.8rem;
  width: 40%;
}

.price_div_target {
  border-radius: 3px;
  border: 1px transparent solid;
  padding: 0.3rem 0.8rem;
  width: 40%;
}

.name_div img {
  width: 40px;
}

.mid_span {
  font-size: 13px;
  font-weight: bold;
}

.bold_body {
  font-size: 12px;
  font-weight: bold;
}

.img-xsmall {
  height: 20px;
  width: 20px;
}

.responsive-title {
  font-size: 1.9vh;
  color: white;
}

.responsive-body {
  font-size: 1.7vh;
  color: white;
}

.img-block {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(
    circle,
    #33373a 0% 30%,
    #2a2c2f 30% 50%,
    #242628 50% 70%,
    #464b51 70% 90%,
    #242628 90% 100%
  );
  background-position: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
</style>
