<template>
  <div class="search-bar-list" id="item-search-">
    <div
      :class="{ active_bar: active && data && data.length > 0 }"
      class="search-wrap"
    >
      <VueIcon height="26px" color="white" icon="bx:search" />
      <input
        v-model="search"
        @keyup.delete="handle"
        @input="handle"
        type="text"
        :placeholder="holder"
        class="search"
      />
      <button class="clear_btn_custom" @click="clear">
        <VueIcon icon="ic:sharp-clear" width="24px" color="gray" />
      </button>
    </div>
    <ul id="myUL" v-if="active && data && data.length > 0">
      <li
        @click="select(item)"
        class="item_link"
        v-for="(item, index) in data"
        :key="index"
        :style="{ '--drop-shadow-color': item.color }"
      >
        <div class="f g10 align-center a1">
          <div class="img-block">
            <img class="small-icon" :src="item.image" />
          </div>
          <div class="rarity-border"></div>
          <span class="size"> {{ item.name }}</span>
        </div>

        <div class="f align-center a2">
          <div class="f flex-column">
            <span class="light-green mid-size">
              {{ formatPrice(item) }}
            </span>
            <span class="silver small-size">From</span>
          </div>

          <VueIcon
            icon="fluent:ios-arrow-right-24-filled"
            width="16px"
            style="color: white"
            class="visibility"
          ></VueIcon>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    holder: {
      type: String,
      default: "Search...",
    },
    data: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      search: "",
      active: false,
    };
  },
  methods: {
    formatPrice(priceData) {
      if (
        !priceData ||
        !priceData.price_info ||
        priceData.price_info.length == 0 ||
        !priceData.price_info[0].price
      )
        return "No Data";
      return (priceData.price_info[0]?.price / 1000).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    handle() {
      if (this.search === "") {
        this.active = false;
        this.$emit("handleSearch", "");
        return;
      }
      this.active = true;
      this.$emit("handleSearch", this.search);
    },
    select(item) {
      this.active = false;
      this.$emit("go-item", item);
    },
    clear() {
      this.search = "";
      this.$emit("handleSearch", this.search);
    },
  },
};
</script>

<style scoped>
.a1 {
  flex: 7;
}
.a2 {
  flex: 1;
}
.size {
  font-size: 13px;
  font-weight: 500;
}

.rarity-border {
  height: 40px;
  width: 2.5px;
  background-color: var(--drop-shadow-color);
  border-radius: 1px;
}

.mid-size {
  font-size: 13px;
}

.small-size {
  font-size: 11px;
}

.small-icon {
  width: 60px;
}

.img-block {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(
    circle,
    #33373a 0% 30%,
    #2a2c2f 30% 50%,
    #242628 50% 70%,
    #464b51 70% 90%,
    #242628 90% 100%
  );
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.visibility {
  visibility: hidden;
}

.search-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #212529;
  border-radius: 2px;
  padding: 0px 10px;
  padding-right: 0px;
  border: 1px solid transparent;
}

.search-wrap:hover {
  transition: 0.3s;
  border: 1px solid #8650ac;
}

.active_bar {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid #8650ac;
}

.search-bar-list {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  padding-left: 0px;
  border-radius: 1px;
}

.search {
  outline-width: 0;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
}

.search-wrap:focus-within {
  border: 1px rgb(134, 80, 172) solid;
}

input:focus {
  outline: none;
}

#myUL {
  position: absolute;
  top: 96%;
  left: 0%;
  list-style-type: none;
  padding: 0;
  margin-top: 0px;
  border: 1px solid #8650ac;
  border-top: none;
  background-color: #212529;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  display: none;
  width: 100%;
  min-width: 250px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  overflow-y: scroll;
  max-height: 500px;
}

.search-bar-list #myUL {
  display: block;
}

#myUL .item_link {
  cursor: pointer;
  background-color: #212529;
  padding: 10px 12px;
  text-decoration: none;
  font-size: 18px;
  color: black;
  display: flex;

  gap: 10px;
  align-items: center;
  min-height: 61px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.item_link:hover .visibility {
  visibility: visible;
}

#myUL .item_link:hover:not(.header) {
  background-color: #040b13;
}

.item_link:hover .small-icon {
  transition: 0.4s;
  transform: scale(1.05);
  filter: drop-shadow(0 0 6px var(--drop-shadow-color));
}

@media screen and (max-width: 576px) {
  #myUL {
    position: absolute;
    top: 40px;
    left: -0px;
    width: 100%;
    min-width: 220px;
  }

  #myUL .item_link {
    padding: 3px 8px;
  }

  .auto_wrap {
    width: 100%;
  }

  .size {
    font-size: 11px;
  }

  .mid-size {
    font-size: 10px;
  }

  .small-size {
    font-size: 9.5px;
  }

  .small-icon {
    min-width: 50px;
    min-height: 50px;
    width: 50px;
  }

  .img-block {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .rarity-border {
    width: 1px;
  }
}
</style>
