<template>
  <div class="f flex-column item_row">
    <div class="inner_column">
      <div class="top_section">
        <div
          @mousedown.middle="this.$emit('go-item', item.item_detail)"
          @click="this.$emit('go-item', item.item_detail)"
          class="currency-div"
        >
          <img
            :src="item.item_detail.image"
            class="currency-icon item-bg"
            :alt="item.item_name"
            loading="lazy"
          />
          <div class="f flex-column g10">
            <div class="f sb">
              <span class="bold">
                {{ item.item_name }}
              </span>
              <button
                @click="$emit('copyText', item.item_name)"
                class="btn_copyname"
              >
                <VueIcon height="20px" color="white" icon="bx:copy" />
              </button>
            </div>
            <div class="f align-bottom sb" style="width:100%">
              <div class="f">
                <VueIcon height="20px" color="white" icon="mdi:steam" />
                <span :style="{ color: '#fff' }" class="text-white mx-2">
                  $ {{ (item.referanceMarket.price / 1000).toFixed(2) || 0 }}
                </span>
              </div>
              <div class="f align-center justify-center">
                <button
                  @click="this.$emit('removeItem', item.id)"
                  class="btn_copyname"
                >
                  <VueIcon
                    height="24px"
                    color="#ff0f0f"
                    icon="lets-icons:remove-light"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="line" :style="{ color: item.item_detail.color }" />

      <div class="f w100 sb align-center" >
        <div class="f align-center">
          <template v-if="item.editingPrice">
            <div class="f align-bottom">
              <div class="mb-1">
                <span>Target Price</span>
                <div class="inner-input-div">
                  <input
                    v-model="newPrice"
                    type="number"
                    step="1"
                    min="0"
                    max="1000"
                    class="input-area-dark"
                  />
                </div>
              </div>
              <button @click="updatePrice" class="edit-btn">
                <img class="small-img-button" :src="tickIcon" />
              </button>
            </div>
          </template>
          <template v-else>
            <div class="f flex-column g5" style="min-width:100px">
              <div class="f g5 sb" style="align-items: flex-end">
                <span class="orange currency">
                  $ {{ (item.target_price / 1000).toFixed(2) }}
                </span>
                <button
                  @click="$emit('startEditingPrice', item)"
                  class="edit-btn"
                >
                  <img class="small-img-button" :src="editIcon" />
                </button>
              </div>

              <span style="color: gray">Target Price</span>
            </div>
          </template>
        </div>
        <div class="f flex-column align-center justify-center">
          <span style="font-size: 11px">{{ DateUtils.getDate(item.created_at) }}</span>
          <span class="mid-font" style="color: gray;font-size: 11px">Created At</span>
        </div>
        <div
          @click="this.$emit('goExternal', item.minimumMarket.url)"
          class="f g5 min_price align-center"
        >
          <div class="f flex-column">
            <span class="mid-font bold green">
              $ {{ item.minimumMarket.price / 1000 }}</span
            >
            <span class="mid-font" style="color: gray">Min. Price</span>
            <span class="mid-font" style="color: gray">{{
              item.minimumMarket.name
            }}</span>
          </div>

          <VueIcon
            class="btn_icon"
            icon="iconamoon:arrow-right-2-light"
            width="34px"
            color="white"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import steamIcon from "../../assets/images/steam-white.png";
import copy from "../../assets/images/copy-white.png";
import edit from "../../assets/images/edit.png";
import tick from "../../assets/images/tick-white.png";
import DateUtils from "@/utils/DateUtils";

export default {
  data() {
    return {
      steamIcon: steamIcon,
      copyIcon: copy,
      editIcon: edit,
      tickIcon: tick,
      newPrice: this.item.newPrice,
      color: "white",
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
  },
  methods: {
    updatePrice() {
      this.$emit("updatePrice", this.item.id, this.newPrice, this.item);
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1000px) {
  .line {
    border: 1.4px solid;
    width: 100%;
  }

  .mid-font {
    font-size: 11px
  }

  .top_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  .orange {
    color: orange
  }

  .green {
    color:rgb(124, 252, 0)
  }

  .red {
    color:#ff0f0f
  }

  span {
    font-size: 1.7vh;
    color: white;
  }

  .item_row {
    background: #21242c;
    border-radius: 0.2rem;
    margin-block: 0.4rem;
    padding: 1rem 0.8rem;
    position: relative;
    transition: 0.3s;
    max-height: 19rem;
  }

  .item_row:hover {
    background-color: #3a3e4a;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s;
  }

  .inner_column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: 600;
    font-size: 20px;
    gap: 0px;
  }

  .name {
    display: flex;
    align-items: center;
  }

  .currency-div {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
  }

  .currency-icon {
    height: 70px !important;
    width: 85px !important;
  }

  .market-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .market-div img {
    width: 40px;
  }

  .edit-btn {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .edit-btn {
    background-color: #212121;
    border-radius: 6px;
    border: 1px solid rgb(100, 99, 99);
    height: 34px;
    width: 34px;
    color: white;
  }

  .small-img-button {
    height: 20px;
    width: 20px;
  }

  .inner-input-div {
    height: 2rem;
    width: 90px;
  }

  .input-area-dark {
    background-color: black;
    color: white;
    font-weight: 600;
    width: 90%;
    min-width: 60px;
  }
}

.responsive-title {
  font-size: 1.9vh;
  color: white;
}

.responsive-body {
  font-size: 1.7vh;
  color: white;
}
</style>
