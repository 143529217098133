<template>
  <div class="background-small">
    <div class="row">
      <div class="f sb">
        <div>
          <h4 class="text-white">{{ title }}</h4>
        </div>

        <div class="f g10">
          <div class="fselectedSizesize flex-column g5">
            <span class="mid_title text-white">{{ titles.gameTitle }}</span>

            <IconDropdown
              :refFunction="setGame"
              :options="gameOptions"
              :selectedItem="selectedGame"
              :selectedIcon="selectedGameIcon"
            />
          </div>

          <div class="f flex-column g5">
            <span class="mid_title text-white">Size</span>

            <SelectorDropdown
              :refFunction="setSize"
              :options="sizeOptions"
              :selectedItem="selectedSize"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-2 specical_margin">
      <div class="market_wrap g5">
        <div class="f flex-column g5">
          <span class="mid_title text-white">{{ titles.sourceTitle }}</span>

          <IconDropdown
            :dropdownType="'source'"
            :refFunction="setMarketFilters"
            :options="markets"
            :selectedItem="selectedSourceMarket"
            :selectedIcon="sourceMarketIcon"
          />
        </div>

        <button @click="rotateIcon" class="circle_btn">
          <VueIcon
            icon="mdi:exchange"
            width="22px"
            height="22px"
            color="white"
            :style="{
              transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',

              transition: '0.2s',
            }"
          >
          </VueIcon>
        </button>

        <div class="f flex-column g5">
          <span class="mid_title text-white">{{ titles.targetTitle }}</span>

          <IconDropdown
            :dropdownType="'target'"
            :refFunction="setMarketFilters"
            :options="markets"
            :selectedItem="selectedTargetMarket"
            :selectedIcon="targetMarketIcon"
          />
        </div>
      </div>
    </div>

    <div class="main_wrap" :style="{ gap: isFilterOpen ? '8px' : '5px' }">
      <div
        v-show="!isMobile"
        class="price_filters"
        :class="{ hidden_block: !isFilterOpen }"
      >
        <div class="f sb filter_header w100">
          <h5 class="text-white" style="margin-bottom: 0">
            {{ titles.filter }}
          </h5>

          <button @click="toggleFilter" class="filter_toggle_wrap">
            <VueIcon
              style="cursor: pointer"
              icon="mdi:filter-outline"
              width="24px"
              color="white"
            >
            </VueIcon>
          </button>
        </div>

        <CheckBox v-model="filterObject.globalMinimum.isChecked">
          <span>{{ filterObject.globalMinimum.text }}</span>
        </CheckBox>

        <div
          v-for="(fieldGroup, index) in commonFilters"
          :key="index"
          class="f flex-column g5 w100"
        >
          <h6 class="text-white">{{ fieldGroup.title }}</h6>

          <div v-if="fieldGroup.fields" class="f sb g5">
            <div
              v-for="(field, idx) in fieldGroup.fields"
              :key="idx"
              class="f flex-column w100"
            >
              <span>{{ field.header }}</span>
              <InputField
                @handle-input="handleInput"
                :input-key="field.modelKey"
                :input-value="filterObject[field.modelKey]"
                :theme="'dark'"
                :type="field.inputType"
                :step="field.step"
                :minimum="field.min"
                :maximum="field.max"
              />
            </div>
          </div>

          <div v-else class="f flex-column w100 g5">
            <div class="f w100 flex-column">
              <InputField
                @handle-input="handleInput"
                :input-value="filterObject[fieldGroup.modelKey]"
                :input-key="fieldGroup.modelKey"
                :theme="'dark'"
                :type="fieldGroup.inputType"
                :step="fieldGroup.step"
                :minimum="fieldGroup.min"
                :maximum="fieldGroup.max"
              />
            </div>
          </div>
        </div>

        <div
          v-for="(collapse, index) in collapseLists"
          :key="index"
          class="f flex-column g5 w100"
        >
          <CollapseList
            v-if="collapse.options && collapse.options.length > 0"
            @check="setFilter"
            :refFunction="toggleCollapse"
            :is-open="collapse.isOpen"
            :type="collapse.type"
            :title="collapse.title"
            :options="collapse.options"
          />
        </div>

        <button class="material_btn" @click="resetGameFilters">
          Reset Filters
        </button>
        <div class="update">
          <span class="mid-span" v-if="items[0]?.last_update">{{
            updatedTime(items[0]?.last_update)
          }}</span>
          <span class="mid-span"> Last Update </span>
        </div>
      </div>

      <div class="content_wrap" :style="{ width: getWidth }">
        <div class="tab">
          <div class="f align-center g10 w100">
            <div
              style="cursor: pointer"
              v-if="
                (!isFilterOpen && !isMobile) || (!mobileFilterOpen && isMobile)
              "
              @click="toggleFilter"
              class="filter_toggle"
            >
              <VueIcon icon="bi:filter" width="24px" color="white"></VueIcon>
            </div>

            <SearchBar class="my-2" @handleSearch="handleSearch"></SearchBar>
          </div>

          <div v-show="!isMobile" class="f g10 align-center">
            <h6 class="text-white">Sort</h6>

            <SortDropdown
              :order-by-function="setOrderBy"
              :options="orderOptions"
              :selectedItem="selectSortBy"
              :selected-dir="selectSortOrder"
              :order-dir-function="setOrderDir"
            />
          </div>
        </div>

        <div v-if="loading" class="f flex-column g10">
          <ShimmerEffect v-for="index in 6" :key="index" />
        </div>

        <div v-else-if="!loading && items.length > 0" class="f flex-column g5">
          <component
            v-for="(item, index) in items"
            :key="index"
            :is="isMobile ? 'PriceCard' : 'ItemRow'"
            @copyText="copyText"
            @addWatchlist="addToWatchlist"
            @go-item="goLink"
            @externalLink="goExternal"
            @onMarketClick="onMarketClick($event, item)"
            :item="item"
          />
        </div>

        <EmptyItems v-else :title="'Items Not Found.'" />

        <div class="page_row pt-2 pb-3 f align-center">
          <PaginationRow
            :pageNumber="filterObject.currentPage"
            :totalPages="totalPages"
            @update:pageNumber="filterObject.currentPage = $event"
          />
        </div>
      </div>
    </div>
  </div>

  <div v-if="mobileFilterOpen" class="bottom_modal">
    <div class="f sb align-center">
      <h6 class="text-white">Filters</h6>

      <div class="close_btn" @click="toggleFilter">&times;</div>
    </div>

    <div class="row align-center my-3">
      <div>
        <h6 class="text-white">Sort</h6>

        <SortDropdown
          :order-by-function="setOrderBy"
          :options="orderOptions"
          :selectedItem="selectSortBy"
          :selected-dir="selectSortOrder"
          :order-dir-function="setOrderDir"
        />
      </div>
    </div>

    <div class="f flex-start my-3" style="width: 200px">
      <CheckBox v-model="filterObject.globalMinimum.isChecked">
        <span>{{ filterObject.globalMinimum.text }}</span>
      </CheckBox>
    </div>

    <div class="f flex-column g10">
      <div
        v-for="(fieldGroup, index) in commonFilters"
        :key="index"
        class="f flex-column g5 w100"
      >
        <h6 class="text-white">{{ fieldGroup.title }}</h6>

        <div v-if="fieldGroup.fields" class="f sb g5">
          <div
            v-for="(field, idx) in fieldGroup.fields"
            :key="idx"
            class="f flex-column w100"
          >
            <span>{{ field.header }}</span>

            <InputField
              @handle-input="handleInput"
              :input-key="field.modelKey"
              :input-value="filterObject[fieldGroup.modelKey]"
              :theme="'dark'"
              :type="fieldGroup.inputType"
              :step="fieldGroup.step"
              :minimum="fieldGroup.min"
              :maximum="fieldGroup.max"
            />
          </div>
        </div>

        <div v-else class="f flex-column w100 g5">
          <div class="f w100 flex-column">
            <InputField
              @handle-input="handleInput"
              :input-value="filterObject[fieldGroup.modelKey]"
              :input-key="fieldGroup.modelKey"
              :theme="'dark'"
              :type="fieldGroup.inputType"
              :step="fieldGroup.step"
              :minimum="fieldGroup.min"
              :maximum="fieldGroup.max"
            />
          </div>
        </div>
      </div>

      <div
        v-for="(collapse, index) in collapseLists"
        :key="index"
        class="f flex-column g5 w100"
      >
        <CollapseList
          v-if="collapse.options && collapse.options.length > 0"
          @check="setFilter"
          :refFunction="toggleCollapse"
          :is-open="collapse.isOpen"
          :type="collapse.type"
          :title="collapse.title"
          :options="collapse.options"
        />
      </div>

      <button class="material_btn" @click="resetGameFilters">
        Reset Filters
      </button>
    </div>
  </div>

  <InfoModal
    v-if="showModal"
    :message="copyMessage"
    @close="showModal = false"
  />

  <MarketPriceHistory
    v-if="isPriceHistoryOpen"
    :items="priceHistoryData"
    :x="modalPosition.x"
    :y="modalPosition.y"
    :interval="timeInterval"
    @selectInterval="selectInterval"
    @close="isPriceHistoryOpen = false"
  >
  </MarketPriceHistory>

  <ItemModal v-if="selectedItem" :item="selectedItem" @close="closeItemModal" />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import WatchlistService from "../services/WatchlistService.js";
import ShimmerEffect from "@/components/listedItemComponents/ShimmerEffect.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import InfoModal from "../components/modal/InfoModal.vue";
import ItemModal from "../components/modal/ItemModal.vue";
import ItemService from "../services/ItemService.js";
import downIcon from "../assets/images/desc.png";
import SelectorDropdown from "../components/dropdownButtons/SelectorDropdown.vue";
import CollapseList from "@/components/dropdownButtons/CollapseList.vue";
import ItemRow from "@/components/tableComponents/ItemRow.vue";
import PriceCard from "@/components/cards/PriceCard.vue";
import IconDropdown from "@/components/dropdownButtons/IconDropdown.vue";
import csgo from "../assets/images/csgo.png";
import MarketOptions from "../core/constants.js";
import SortDropdown from "../components/dropdownButtons/SortDropdown.vue";
import InputField from "../components/inputFields/InputField.vue";
import MarketPriceHistory from "../components/modal/MarketPriceHistory.vue";

export default {
  data() {
    return {
      last_update: null,
      isRotated: false,
      isPriceHistoryOpen: false,
      selectedPriceHistoryItem: null,
      selectedPriceHistoryInterval: 7,
      title: "Price Comparison",
      sizeOptions: ["10", "15", "25", "30"],
      titles: {
        sourceTitle: "Source Market",
        targetTitle: "Target Market",
        prices: "Price",
        rois: "ROI",
        filter: "Filters",
        gameTitle: "Game",
        priceTitle: "Price",
        type: "Type",
        exteriorTitle: "Exterior",
        volumeTitle: "Volume",
        minROI: "Min ROI",
        maxROI: "Max ROI",
        qty: "Quantity",
        minPrice: "Min Price",
        maxPrice: "Max Price",
      },
      rarityList: [],
      extraList: [],
      exteriorList: [],
      typeList: [],
      collapseLists: [],
      markets: [],
      volumeOptions: [
        "5",
        "10",
        "15",
        "20",
        "25",
        "30",
        "40",
        "50",
        "100",
        "200",
        "300",
        "400",
        "500",
        "600",
        "700",
      ],
      fields: [],
      items: [],
      priceHistoryData: [],
      gameOptions: [
        { name: "CS2", icon: csgo, appID: 730 },
        /*{ name: "Dota2", icon: dota, appID: 570 },
        { name: "TF2", icon: tf2, appID: 440 },
        { name: "Rust", icon: rust, appID: 252490 },*/
      ],
      timeInterval: [
        { name: "7 D", code: "7_d", value: 7 },
        { name: "14 D", code: "14_d", value: 14 },
        { name: "30 D", code: "30_d", value: 30 },
      ],
      clickedItems: [],
      orderOptions: ["Profit Ratio", "Profit", "Name", "Quantity"],
      isPageRefreshed: true,
      isFilterOpen: true,
      rarityCollapse: false,
      extraCollapse: false,
      typeCollapse: false,
      exteriorCollapse: false,
      loading: true,
      isDropdownOpen: false,
      selectedItem: null,
      showModal: false,
      copyMessage: "Item name copied successfully",
      searchTimeout: null,
      searchTerm: "",
      selectedSize: "10",
      selectedTargetMarket: "",
      selectedGame: "CS2",
      selectedGameIcon: csgo,
      selectedType: "All",
      sortOrder: "DESC",
      selectedSourceMarket: "",
      selectSortBy: "Profit Ratio",
      selectSortOrder: "DESC",
      orderIcon: downIcon,
      sourceMarketIcon: null,
      targetMarketIcon: null,
      contentWrapWidth: "85%",
      mobileFilterOpen: false,
      appID: 730,
      game: "cs2",
      totalPages: 6,
      marketsLoading: true,
      commonFilters: [],
      modalPosition: { x: 0, y: 0 },
    };
  },
  methods: {
    async addToWatchlist(name) {
      const response = await WatchlistService.addWatchlist({
        appid: 730,
        market_hash_name: name,
      });

      if (response) this.showInfoModal("Item Added to your watchlist", 5000);
      else this.showInfoModal("Item already in your watchlist.", 5000);
    },
    async showInfoModal(msg, interval) {
      this.showModal = true;

      this.copyMessage = msg;

      setTimeout(() => {
        this.showModal = false;
      }, interval || 5000);
    },
    async setOrderDir(isAscOrder) {
      this.filterObject.sortOrder = this.selectSortOrder = isAscOrder
        ? "ASC"
        : "DESC";
    },
    async setOrderBy(option) {
      this.selectSortBy = option;
      this.filterObject.sortBy =
        option.toLowerCase() === "profit ratio"
          ? "profitRatio"
          : option.toLowerCase();
    },
    async onMarketClick(event, item) {
      this.selectedPriceHistoryItem = item;
      this.selectedPriceHistoryItem.interval = 7;
      this.modalPosition = {
        x: event.clientX,
        y: event.clientY,
      };
      await this.getItemPriceHistory();
      await this.setClickedItems(item);
      this.mapClickedItems();
    },
    async getItemPriceHistory() {
      const res = await ItemService.getPriceHistory({
        slug: this.selectedPriceHistoryItem.slug,
        interval: this.selectedPriceHistoryInterval,
        game: this.selectedPriceHistoryItem.app_id,
        market: this.selectedPriceHistoryItem.target_id,
      });

      if (!res) {
        this.isPriceHistoryOpen = false;
        this.priceHistoryData = [];
      }

      this.isPriceHistoryOpen = true;
      this.priceHistoryData = res;
    },
    async selectInterval(time) {
      if (!time) return;
      this.selectedPriceHistoryInterval = time;
      await this.getItemPriceHistory();
    },
    async setClickedItems(item) {
      const storageKey = "clickedItems";
      const expirationKey = "clickedItemsExpiration";
      const oneHour = 60 * 60 * 1000;
      const now = new Date().getTime();

      const expirationTime = localStorage.getItem(expirationKey);
      if (!expirationTime || now > parseInt(expirationTime, 10)) {
        localStorage.setItem(storageKey, JSON.stringify([]));
        localStorage.setItem(expirationKey, (now + oneHour).toString());
      }

      const selectedItems = JSON.parse(
        localStorage.getItem(storageKey) || "[]"
      );

      const obj = {
        app_id: item.app_id,
        item_id: item.item_id,
        source_id: item.source_id,
        target_id: item.target_id,
      };

      const isItemExists = selectedItems.some(
        (clickedItem) =>
          clickedItem.app_id === obj.app_id &&
          clickedItem.item_id === obj.item_id &&
          clickedItem.source_id === obj.source_id &&
          clickedItem.target_id === obj.target_id
      );

      if (!isItemExists) {
        selectedItems.push(obj);
      }
      localStorage.setItem("clickedItems", JSON.stringify(selectedItems));
    },
    rotateIcon() {
      this.isRotated = !this.isRotated;

      [this.filterObject.sourceMarkets, this.filterObject.targetMarkets] = [
        this.filterObject.targetMarkets,
        this.filterObject.sourceMarkets,
      ];

      const source = this.filterObject.sourceMarkets?.[0]?.toLowerCase();
      const sourceMarket = this.markets.find(
        (e) => e.name.toLowerCase() === source
      );
      if (sourceMarket) {
        this.selectedSourceMarket = sourceMarket.name;
        this.sourceMarketIcon = sourceMarket.icon;
      }

      const target = this.filterObject.targetMarkets?.[0]?.toLowerCase();
      const targetMarket = this.markets.find(
        (e) => e.name.toLowerCase() === target
      );
      if (targetMarket) {
        this.selectedTargetMarket = targetMarket.name;
        this.targetMarketIcon = targetMarket.icon;
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    showItemModal(item) {
      this.selectedItem = item;
    },
    closeItemModal() {
      this.selectedItem = null;
    },
    copyText(item) {
      const textToCopy = item;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          this.copyMessage = "Item name copied successfully";
          this.showModal = true;
          setTimeout(() => {
            this.showModal = false;
          }, 2500);
        })
        .catch((error) => {
          this.copyMessage = "Error copying text" + error;
        });
    },
    handleInput(type, key, value) {
      if (type == "number") {
        value = parseFloat(value);
      } else {
        value = value.toString();
      }
      this.filterObject[key] = value;
    },
    async handleSearch(search) {
      clearTimeout(this.searchTimeout);
      this.searchTerm = search;
      this.filterObject.currentPage = 1;
      this.searchTimeout = setTimeout(() => {
        this.getItemsByQuery();
      }, 1000);
    },
    async getItemsByQuery() {
      this.loading = true;
      this.items = await ItemService.getComparison({
        game: this.filterObject.appID,
        currentPage: this.filterObject.currentPage,
        pageSize: this.filterObject.pageSize,
        sortBy: this.filterObject.sortBy,
        sortOrder: this.filterObject.sortOrder,
        searchQuery: this.searchTerm,
        priceFrom: this.filterObject.minPrice,
        priceTo: this.filterObject.maxPrice,
        minROI: this.filterObject.minRoi,
        maxROI: this.filterObject.maxRoi,
        quantity: this.filterObject.qty,
        volume: parseInt(this.filterObject.volume),
        type: this.filterObject.type,
        exterior: this.filterObject.exterior,
        rarity: this.filterObject.rarity,
        extra: this.filterObject.extra,
        source: this.filterObject.sourceMarkets,
        target: this.filterObject.targetMarkets,
        globalMinimum: this.filterObject.globalMinimum?.isChecked ? 1 : 0,
      });

      this.mapClickedItems();
      this.loading = false;
    },
    mapClickedItems() {
      if (!this.items || this.items.length == 0) return;

      const clickedItems = JSON.parse(
        localStorage.getItem("clickedItems") || "[]"
      );

      this.items = this.items.map((e) => {
        const isClicked = clickedItems.some(
          (clickedItem) =>
            clickedItem.app_id === e.app_id &&
            clickedItem.item_id === e.item_id &&
            clickedItem.source_id === e.source_id &&
            clickedItem.target_id === e.target_id
        );
        return {
          ...e,
          isClicked,
        };
      });
    },
    goLink(item) {
      const routeData = this.$router.resolve({
        name: "item",
        params: { slug: item.slug, game: item.game },
      });
      window.open(routeData.href, "_blank");
    },
    goExternal(link) {
      window.open(link, "_blank");
    },
    toggleFilter() {
      if (!this.isMobile) return (this.isFilterOpen = !this.isFilterOpen);

      this.mobileFilterOpen = !this.mobileFilterOpen;
      document.querySelector("html").style.overflowY = this.mobileFilterOpen
        ? "hidden"
        : "auto";
    },
    mapSavedFilters() {
      if (this.filterObject.extra.length > 0) {
        this.filterObject.extra.forEach((filter) => {
          const element = this.extraList.find((e) => e.label === filter);

          if (element) element.checked = true;
        });
      }

      if (this.filterObject.rarity.length > 0) {
        this.filterObject.rarity.forEach((filter) => {
          const element = this.rarityList.find((e) => e.label === filter);

          if (element) element.checked = true;
        });
      }

      if (this.filterObject.exterior.length > 0) {
        this.filterObject.exterior.forEach((filter) => {
          const element = this.exteriorList.find((e) => e.label === filter);
          if (element) element.checked = true;
        });
      }

      if (this.filterObject.type.length > 0) {
        this.filterObject.type.forEach((filter) => {
          const element = this.typeList.find((e) => e.label === filter);

          if (element) element.checked = true;
        });
      }

      if (this.filterObject.sourceMarkets?.length > 0) {
        const source = MarketOptions.find(
          (e) => e.name.toLowerCase() === this.filterObject.sourceMarkets[0]
        );

        if (!source) {
          this.selectedSourceMarket = this.markets[0].name;
          this.sourceMarketIcon = this.markets[0].icon;
          return;
        }
        this.selectedSourceMarket = source.name;
        this.sourceMarketIcon = source.icon;
      }

      if (this.filterObject.targetMarkets?.length > 0) {
        const target = MarketOptions.find(
          (e) => e.name.toLowerCase() === this.filterObject.targetMarkets[0]
        );

        if (!target) {
          this.selectedTargetMarket = this.markets[0].name;
          this.targetMarketIcon = this.markets[0].icon;
          return;
        }

        this.selectedTargetMarket = target.name;
        this.targetMarketIcon = target.icon;
      }

      if (this.filterObject.pageSize) {
        this.selectedSize = this.filterObject.pageSize.toString();
      }
    },
    setFilters() {
      this.commonFilters = [
        {
          title: this.titles.prices,
          fields: [
            {
              header: "From",
              modelKey: "minPrice",
              inputType: "number",
              step: 0.01,
              min: 0,
              max: 1000000,
            },
            {
              header: "To",
              modelKey: "maxPrice",
              inputType: "number",
              step: 0.01,
              min: 0,
              max: 1000000,
            },
          ],
        },
        {
          title: this.titles.rois,
          fields: [
            {
              header: "Minimum",
              modelKey: "minRoi",
              inputType: "number",
              step: 0.01,
              min: 0,
              max: 10000,
            },
            {
              header: "Maximum",
              modelKey: "maxRoi",
              inputType: "number",
              step: 0.01,
              min: 0,
              max: 10000,
            },
          ],
        },
        {
          header: "",
          modelKey: "qty",
          inputType: "number",
          step: 0.01,
          min: 0,
          max: 10000,
          title: this.titles.qty,
        },
      ];
    },
    resetGameFilters() {
      this.resetFilters(this.game);
    },
    async setupFilterOptions() {
      await this.ensureFilterOptions({ appID: this.appID, game: this.game });
      if (this.filterOptions) {
        this.mapMarkets(this.filterOptions.markets);
        this.mapFilterTags(this.filterOptions.filters);
      }
    },
    mapMarkets(items) {
      try {
        this.markets = items.map((item) => {
          const el = MarketOptions.find((e) => e.name == item.name);

          if (!el || !el.icon) return;

          return {
            name: item.name,
            id: item.market_id,
            icon: el.icon,
            isChecked: false,
          };
        });
      } catch (error) {
        this.markets = [];
      }
    },
    mapFilterTags(items) {
      if (!items || items.length == 0) return false;

      try {
        const listMappings = {
          Type: "typeList",
          Rarity: "rarityList",
          Extra: "extraList",
          Exterior: "exteriorList",
        };

        Object.values(listMappings).forEach((listName) => {
          this[listName] = null;
        });

        items.forEach((e) => {
          if (!e.items || e.items.length === 0) return;
          const listName = listMappings[e.name];
          this[listName] = null;

          if (listName) {
            this[listName] =
              e.items && e.items.length > 0
                ? e.items.map((item) => ({
                    label: item.name,
                    isChecked: false,
                    ...(e.name === "Rarity" || e.name === "Extra"
                      ? { color: item.color }
                      : {}),
                  }))
                : null;
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    setCollapseList() {
      this.collapseLists = [
        {
          title: "Type",
          type: "type",
          options: this.typeList,
          isOpen: false,
          setFunction: this.setType,
        },
        {
          title: "Exterior",
          type: "exterior",
          options: this.exteriorList,
          isOpen: false,
          setFunction: this.setExterior,
        },
        {
          title: "Rarity",
          type: "rarity",
          options: this.rarityList,
          isOpen: false,
          setFunction: this.setRarity,
        },
        {
          title: "Extra",
          type: "extra",
          options: this.extraList,
          isOpen: false,
          setFunction: this.setExtra,
        },
      ];
    },
    toggleCollapse(type) {
      const collapseItem = this.collapseLists.find(
        (collapse) => collapse.type === type
      );

      if (collapseItem) {
        collapseItem.isOpen = !collapseItem.isOpen;
      }
    },
    async setFilter(name, type) {
      const listName = `${type}List`;
      const filterName = type;
      const list = this[listName];
      if (list) {
        const item = list.find((e) => e.label === name);
        if (item) {
          item.checked = !item.checked;
          this.filterObject[filterName] = list
            .filter((e) => e.checked)
            .map((e) => e.label);
        }
      }
    },
    async setMarketFilters(market, type) {
      const isSource = type === "source";
      const marketType = isSource ? "source" : "target";

      this.filterObject[`${marketType}Markets`].pop();
      this.filterObject[`${marketType}Markets`].push(market.name.toLowerCase());
      this[`${marketType}MarketIcon`] = market.icon;
      this[
        `selected${
          marketType.charAt(0).toUpperCase() + marketType.slice(1)
        }Market`
      ] = market.name;

      this.isDropdownOpen = false;
    },
    async setSize(size) {
      this.selectedSize = size;
      this.filterObject.pageSize = size;
      this.isDropdownOpen = false;
    },
    async setGame(type) {
      this.selectedGame = type.name;
      this.selectedGameIcon = type.icon;
      this.filterObject.appID = type.appID;
      this.game = type.appID == 730 ? "cs2" : "dota2";
      this.appID = type.appID;
      await this.setupFilterOptions();
      this.setCollapseList();
      this.isDropdownOpen = false;
    },
    updatedTime(date) {
      if (!date) return "";

      const [datePart, timePart] = date.split(" ");
      const [day, month, year] = datePart.split("-");
      const [hours, minutes] = timePart.split(":");
      const formattedDateString = `${year}-${month}-${day}T${hours}:${minutes}:00`;
      const dateProxy = new Date(formattedDateString);
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
        timeZoneName: "short",
      }).format(dateProxy);

      return formattedDate;
    },
    ...mapActions("ui", ["checkMobile"]),
    ...mapActions("filters", [
      "initializeFilters",
      "resetFilters",
      "ensureFilterOptions",
    ]),
  },
  async created() {
    this.isPageRefreshed = true;
    await this.initializeFilters(this.game);
    await this.setupFilterOptions();
    this.mapSavedFilters();
    this.setFilters();
    this.setCollapseList();
    this.getItemsByQuery();
    this.isPageRefreshed = false;
  },
  computed: {
    ...mapGetters("filters", ["getFilterOptions", "getComparisonFilters"]),
    filterOptions() {
      return this.getFilterOptions(this.game);
    },
    filterObject() {
      const filter = this.getComparisonFilters(this.game);
      return filter;
    },
    getWidth() {
      if (!this.isMobile && !this.isFilterOpen) return "100%";
      if (!this.isMobile && this.isFilterOpen) return "85%";
      if (this.isMobile) return "100%";
      return "100%";
    },
    ...mapGetters("ui", ["isMobile"]),
  },
  mounted() {
    this.checkMobile();
  },
  watch: {
    filterObject: {
      async handler() {
        if (!this.isPageRefreshed) {
          this.$store.dispatch("filters/updateFilter", {
            game: this.game,

            filters: this.filterObject,
          });

          clearTimeout(this.searchTimeout);

          this.searchTimeout = setTimeout(() => {
            this.getItemsByQuery();
          }, 1000);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    SortDropdown,
    InfoModal,
    ItemRow,
    CollapseList,
    ItemModal,
    SelectorDropdown,
    SearchBar,
    IconDropdown,
    PriceCard,
    ShimmerEffect,
    InputField,
    MarketPriceHistory,
  },
};
</script>

<style scoped>
.mid-span {
  color: orange;
  padding: 0;
}

.background-small {
  position: relative;
  min-height: 120vh;
}

.update {
  padding: 4px 8px;
  font-size: 11px;
  color: white;
  background-color: #212529;
  width: 100%;
  border-radius: 5px;
  height: 45px;
  display: flex;
  flex-direction: column;
}

.bottom_modal {
  height: 77vh;
  background-color: #11101d;
  position: fixed;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  top: auto;
  overflow-y: auto;
  overflow-x: hidden;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  animation: animatebottom 0.4s;
}

@keyframes animatebottom {
  from {
    bottom: -300px;

    opacity: 0;
  }

  to {
    bottom: 0;

    opacity: 1;
  }
}

.bottom_modal::-webkit-scrollbar {
  display: none;
}

.filter_toggle_wrap {
  display: flex;

  align-items: center;

  justify-content: center;

  border: 1px solid transparent;

  width: 40px;

  height: 35px;

  border-radius: 6px;

  background-color: transparent;

  transition: 0.4s;
}

.filter_toggle_wrap:hover {
  transition: 0.4s;

  border: 1px solid rgb(134, 80, 172);

  background-color: rgb(134, 80, 172);
}

.img {
  width: 90px;
}

tr:hover img {
  transition: 0.2s;

  transform: scale(1.1);
}

tr:not(hover) img {
  transition: 0.2s;

  transform: scale(1);
}

.table > tbody > tr {
  border: 1px solid transparent;
}

.table > tbody > tr:hover {
  background-color: transparent;
}

.filter_toggle {
  cursor: pointer;

  display: flex;

  flex-direction: row;

  align-items: center;

  justify-content: space-between;

  padding: 8px;

  width: auto;

  height: 40px;

  gap: 8px;

  border-radius: 4px;

  border: 1px solid rgb(134, 80, 172);
}

.filter_toggle:hover {
  background-color: rgb(134, 80, 172);

  transition: 0.3s;
}

.material_btn {
  width: 100%;
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-weight: 400;
  border-radius: 3px;
  padding: 0 15px;
  border-radius: 4px;
  background: transparent;
  line-height: 1.15;
  font-size: 12px;
  height: 40px;
  word-spacing: 0px;
  letter-spacing: 0.0892857143em;
  text-decoration: none;
  text-transform: uppercase;
  min-width: 64px;
  border: 1.5px solid white;
  text-align: center;
  transition: background 280ms cubic-bezier(0.4, 0, 0.2, 1);
  color: white;
}

.material_btn:hover {
  transition: 0.3s;

  border-color: rgb(134, 80, 172);
}

.close_btn {
  font-size: 40px;

  cursor: pointer;

  color: red;

  transition: 0.4s;
}

.table_span {
  font-size: 14px;

  font-weight: bold;
}

.table_span_small {
  font-size: 12px;

  font-weight: bold;
}

.circle_btn {
  background-color: #313156;
  border-radius: 1px;
  border: 1px solid #6e47b1;
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  padding: 0;
  width: 40px;
}

.circle_btn:hover {
  transition: 0.3s;
  background-color: #7e52c9;
}

.main_wrap {
  display: flex;
  justify-content: center;
  width: calc(100% - 0.3rem);
}

.content_wrap {
  width: 85%;
  transition: 0.3s;
}

.market_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 0.5rem;
  gap: 4%;
}

.specical_margin {
  margin-left: 0.1rem;
  margin-right: 0.3rem;
}

.price_filters {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  min-width: 220px;
  max-width: 500px;
  gap: 18px;
  background-color: #313156;
  padding: 1rem 0.8rem;
  border-radius: 4px;
  width: 15%;
  transition: 0.3s;
}

.hidden_block {
  display: none;
}

.filter_header {
  width: 100%;
  border-bottom: 1px solid rgb(134, 80, 172);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.tab {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  background-color: #313156;
  overflow: hidden;
  border-radius: 4px;
  color: white;
  margin-bottom: 0.5rem;
  margin-top: 0px;
  border-bottom: none;
}

.mid_title {
  font-size: bold;
  font-size: 13px;
}

@media screen and (max-width: 800px) {
  .content_wrap {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  h5 {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: bold;
  }

  .market_wrap {
    justify-content: space-between;
    padding: 9px 2px;
    gap: 6px;
  }
  .content_wrap {
    width: 100%;
    transition: 0.3s;
  }

  .circle_btn {
    height: 40px;
    width: 38px;
  }
}
</style>
