<template>
  <Line :options="options" :data="chartData" />
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default {
  name: "App",
  components: {
    Line,
  },
  props: {
    data: Array,
    labelName: {
      type: String,
      default: "Date",
    },
    trend: {
      type: String,
      default: "Up",
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.data.map((item) => item.date),
        datasets: [
          {
            label: this.labelName,
            data: this.data.map((item) => item.value),
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx;

              const gradient = canvas.createLinearGradient(0, 0, 0, 80);

              const colorPalette =  this.trend == "up" ?  this.colors.green : this.colors.red;

              gradient.addColorStop(0, colorPalette.half);
              gradient.addColorStop(0.75, colorPalette.quarter);
              gradient.addColorStop(1, colorPalette.zero);

              return gradient;
            },
            borderColor:
              this.trend == "up"
                ? this.colors.green.default
                : this.colors.red.default,
            fill: true,
            tension: 0.5,
            pointRadius: 0,
            pointHoverRadius: 8,
          },
        ],
      };
    },
  },
  data() {
    return {
      colors: {
        purple: {
          default: "rgba(149, 76, 233, 1)",
          half: "rgba(149, 76, 233, 0.5)",
          quarter: "rgba(149, 76, 233, 0.25)",
          zero: "rgba(149, 76, 233, 0)",
        },
        green: {
          default: "rgba(141, 215, 94, 1)",
          half: "rgba(141, 215, 94, 0.5)",
          quarter: "rgba(141, 215, 94, 0.25)",
          zero: "rgba(141, 215, 94, 0)",
        },
        red: {
          default: "rgba(255, 15, 15, 1)",
          half: "rgba(255, 15, 15, 0.5)",
          quarter: "rgba(255, 15, 15, 0.25)",
          zero: "rgba(255, 15, 15, 0)",
        },
        indigo: {
          default: "rgba(80, 102, 120, 1)",
          quarter: "rgba(80, 102, 120, 0.25)",
        },
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          line: {
            borderColor: "#red",
            borderWidth: 1,
          },
          point: {
            radius: 0,
          },
        },
        tooltips: {
          enabled: false,
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      },
    };
  },
};
</script>
