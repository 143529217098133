import App from "./App.vue";
import router from "./router";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import AppNavbar from "./components/shared/AppNavbar.vue";
import SideBar from "./components/shared/SideBar.vue";
import SideBarMobile from '@/components/shared/SidebarMobile.vue'
import FooterBar from "./components/shared/FooterBar.vue";
import ErrorModal from './components/modal/ErrorModal.vue';
import CustomTab from "./components/tab/CustomTab.vue";
import InfoModal from './components/modal/InfoModal.vue';
import ToolTip from './components/shared/ToolTip.vue';
import PaginationBar from './components/shared/PaginationBar.vue';
import CheckBox from './components/checkbox/CheckBox.vue';
import DotLoaderVue from "./components/loaders/DotLoader.vue";
import Vue3Lottie from 'vue3-lottie'
import VueDatePicker from '@vuepic/vue-datepicker';
import store from './store/store.js';
import VueGtag from "vue-gtag";
import '@vuepic/vue-datepicker/dist/main.css';
import PaginationRow from "./components/shared/PaginationRow.vue";
import EmptyItems from './components/shared/EmptyItems.vue';
import { Icon } from '@iconify/vue';

import { createHead } from '@unhead/vue';
import { createApp } from "vue";

const app = createApp(App);
const head = createHead();

app.use(Vue3Lottie);
app.use(head)
app.component('VueDatePicker', VueDatePicker);
app.component("AppNavbar", AppNavbar);
app.component("SideBar", SideBar);
app.component("SideBarMobile", SideBarMobile);
app.component("FooterBar", FooterBar);
app.component("PaginationRow", PaginationRow);
app.component("ErrorModal", ErrorModal);
app.component("InfoModal", InfoModal);
app.component("PaginationBar", PaginationBar);
app.component("DotLoader", DotLoaderVue)
app.component("VueIcon", Icon);
app.component("EmptyItems", EmptyItems);
app.component("ToolTip", ToolTip);
app.component("CheckBox", CheckBox);
app.component("CustomTab", CustomTab);
app.use(router);
app.use(store);

app.use(VueGtag, {
    config: {
        id: process.env.VUE_APP_GTAG
    },
    enabled: process.env.VUE_APP_MODE === 'prod'
});

app.mount('#app');


