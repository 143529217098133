<template>
  <div :class="colSize">
    <div class="card-heading px-1 py-2 market-card">
      <div>
        <h6 class="gray mid">{{ head }}</h6>
      </div>
      <div class="mini-stat-icon float-right f sb align-center">
        <div class="f g5 align-bottom">
          <h5>{{ value }}</h5>
          <span v-if="percentage" class="ratio green">
            (+{{ percentage }}%)</span
          >
        </div>

        <img class="img-small" v-if="iconPath" :src="iconPath" alt="Icon" />
      </div>

      <div>
        <span class="text-sm silver"
          >Since previous {{ periode }} day</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    head: {
      type: String,
      default:""
    },
    value: {
      type: String,
      default: "0",
    },
    periode: String,
    percentage: {
      type: String,
      default: "0",
    },
    colSize: String,
    iconPath: String,
    percentageSymbol: Boolean,
    dollarSymbol: Boolean,
  },
};
</script>

<style scoped>
.img-small {
  width: 30px;
  height: 30px;
}

h5 {
  margin: 0;
}

.mid {
  font-size: 0.8rem;
  margin:0;
  padding:0
}

.ratio {
  font-size: 0.8rem;
  font-weight: 500;
}
</style>
