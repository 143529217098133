<template>
  <div class="item_grid_row g20">
    <div
      style="cursor: pointer"
      @click="goRoute(item.item_detail)"
      @click.middle.exact="goRoute(item.item_detail)"
      class="f align-center justify-center item-bg"
    >
      <img
        v-if="item.item_detail.image"
        class="img"
        :style="{ '--drop-shadow-color': item.item_detail.color }"
        :src="item.item_detail.image"
      />
    </div>
    <div class="sb f g5 align-center">
      <div style="cursor: pointer" class="f flex-column flex-start">
        <span class="mid_span" :style="{ color: typeColor }">
          {{ getType() }}</span
        >
        <span
          class="mid_span"
          :style="{ color: item.item_detail.color || 'white' }"
          >{{ item.item_detail.short_name }}</span
        >
        <span class="mid_span">{{ item.item_detail.exterior }}</span>
        <div class="f align-bottom">
          <img class="img-xsmall mt-1" :src="getMarketIcon('Buff.163')" />
          <span :style="{ color: '#fff' }" class="text-white mx-2">
            $ {{ (item.price / 1000).toFixed(2) || 0 }}
          </span>
        </div>
      </div>
      <div class="f g10">
        <button
          @click="copyText(item.item_detail.name)"
          aria-label="Copy"
          class="btn_copyname"
        >
          <VueIcon height="22px" color="white" icon="bx:copy" />
        </button>
        <button
          @click="addWatchlist(item.name)"
          aria-label="Copy"
          class="btn_copyname"
        >
          <VueIcon
            height="22px"
            width="20px"
            color="white"
            icon="bx:bookmark"
          />
        </button>
      </div>
    </div>
    <div v-for="(period, index) in periods" :key="index" class="price_div">
      <span
        class="mid_span"
        :style="{ color: period.change >= 0 ? 'light-green' : 'red' }"
      >
        {{
          parseFloat(period.value).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        }}
      </span>
      <span
        class="mid_span"
        :style="{ color: period.change >= 0 ? '#3fd986' : 'red' }"
      >
        {{
          parseFloat(period.change).toLocaleString("en-US", {
            style: "percent",
          })
        }}
      </span>
    </div>
    <div class="price_history_div">
      <LineChart
        v-if="item.item_detail.prices && item.item_detail.prices.length > 0"
        :data="item.item_detail.prices"
        :trend="trend"
        :labelName="'Price'"
      />
    </div>
  </div>
</template>

<script>
import steamIcon from "../../assets/images/steam-white.png";
import copy from "../../assets/images/copy-white.png";
import edit from "../../assets/images/edit.png";
import tick from "../../assets/images/tick-white.png";
import DateUtils from "@/utils/DateUtils";
import LineChart from "../charts/MiniLineChart.vue";
import MarketOptions from "../../core/constants.js";

export default {
  data() {
    return {
      MarketOptions,
      trend: "up",
      steamIcon: steamIcon,
      copyIcon: copy,
      editIcon: edit,
      tickIcon: tick,
      typeColor: "white",
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goRoute(item) {
      this.$emit("goItem", item);
    },
    addWatchlist(name) {
      this.$emit("addWatchlist", name);
    },
    copyText(name) {
      this.$emit("copyText", name);
    },
    goExternalLink(link) {
      this.$emit("externalLink", link);
    },
    getMarketIcon(name) {
      const found = this.MarketOptions.find((e) => e.name === name);
      if (found) return found.icon;
    },
    getType() {
      if (this.item.item_detail.is_stattrak) {
        this.typeColor = "#CF6830";
        return "StatTrak™";
      }
      if (this.item.item_detail.is_souvenir) {
        this.typeColor = "#FFD601";
        return "Souvenir";
      }
      this.typeColor = "white";
      return this.item.item_detail.type;
    },
  },
  computed: {
    DateUtils() {
      return DateUtils;
    },
    periods() {
      return [this.item.change7D, this.item.change14D, this.item.change30D];
    },
  },
  created() {
    if (this.item.change7D.change && this.item.change7D.change > 0)
      return (this.trend = "up");
    if (this.item.change7D.change && this.item.change7D.change < 0)
      return (this.trend = "down");
    return (this.trend = "down");
  },
  components: { LineChart },
};
</script>

<style scoped>
.item_grid_row {
  display: grid;
  grid-template-columns: 1.2fr 2fr 1fr 1fr 1fr 2fr;
  background: #21242c;
  border-radius: 0.2rem;
  padding: 0.6rem 0.8rem;
  transition: 0.2s;
  max-height: 9rem;
  min-height: 6rem;
  border: 1px transparent solid;
}

.price_div {
  padding: 0.2rem 0.4rem;
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
}

.price_history_div {
  max-width: 220px;
  max-height: 5rem;
}

.steam_wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  padding: 0rem 0.8rem;
  border-radius: 4px;
}

.steam_wrap:hover {
  cursor: pointer;
  transition: 0.4s;
  background-color: #21242c;
}

.item_grid_row:hover {
  background-color: transparent;
  border: 1px #8650ac solid;
}

.item_grid_row:hover .img {
  transition: 0.2s;
  transform: scale(1.1);
  filter: drop-shadow(0 0 11px var(--drop-shadow-color));
}

.item_grid_row:not(hover) .img {
  transition: 0.2s;
  transform: scale(1);
}

.small_span {
  font-size: 12px;
  font-weight: bold;
}

.mid_span {
  font-size: 13px;
  font-weight: 400;
}

.body_span {
  font-size: 14px;
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}

.img-xsmall {
  height: 25px;
  width: 25px;
}

.img {
  width: 90px;
}

.mid_span {
  font-size: 13px;
  font-weight: 700;
}
</style>
