<template>
  <div class="item-modal-history" @click="closeModal">
    <div
      class="modal-content-history"
      :style="{
        top: `35%`,
        right: `60px`,
      }"
      @click.stop
    >
      <div class="header-history">
        <h6>Sales History</h6>
        <span class="close-history" @click="closeModal">&times;</span>
      </div>
      <div class="interval_wrap_history">
        <div
          @click="setTab(item)"
          v-for="(item, index) in interval"
          :key="index"
          :class="{ interval_info: true, active: item.code === selectedTab }"
        >
          <span> {{ item.name }}</span>
        </div>
      </div>
      <div class="body-history">
        <LineChart
          v-if="items && items.length > 0"
          :data="items"
          :labelName="'Price'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "../charts/LineChart.vue";
export default {
  props: {
    items: {
      type: Array,
      required: false
    },
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    interval: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedTab: '7_d',
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    setTab(item) {
      this.selectedTab = item.code;
      this.$emit("selectInterval", item.value);
    },
  },
  components: { LineChart },
};
</script>

<style scoped>
h6 {
  color: white;
}

.item-modal-history {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
}

.modal-content-history {
  position: absolute;
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  min-height: 350px;
  width: 100%;
}

.header-history {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  font-size: 15px;
}

.close-history {
  font-size: 26px;
  cursor: pointer;
  color: white;
}

.close-history:hover {
  color: red;
}

.interval_wrap_history {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  border: 1px solid #954ce9;
  border-radius: 3px;
  padding: 0rem;
  width: 40%;
  height: 30px;
}

.interval_info {
  width: 100%;
  height: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.interval_info span{
  font-size: 13px;
  font-weight: 600;
}

.active {
  background-color: #954ce9;
  transition: 0.4s;
}

.body-history {
  height: 100%;
  width: 100%;
}
</style>
